// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isAuth: false,
  isSubmitting: false,
  isFetching: false,
  userInfo: {},
  setting: {},
  homePageData: {},
  pageData: {},
  userParams: {},
  faqData: [],
  isData: false,
  isHead: {},
  pagination: {},
  toaster: {
    open: false,
    message: "test",
    severity: "success",
  },
  dialogOpen: false,
  notificationCount: 0,
  notificationList: [],
};
