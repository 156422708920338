import HomePage from "../Pages/Home";
import Faq from "../Pages/Faq";
import cmsPage from "../Pages/Cms";
import NotFoundPage from "../Pages/NotFound";
import ContactUs from "../Pages/ContactUs";
import googleLogin from "../Pages/GoogleLogin";

/**
 * Auth
 */
import LoginPage from "../Pages/Authentication/SignIn";
import RegisterPage from "../Pages/Authentication/SignUp";
import ForgotPasswordPage from "../Pages/Authentication/ForgotPassword";
import ResetPasswordPage from "../Pages/Authentication/RestPassword";

export const RoutesPage = [
  { path: "/", component: HomePage, title: "Home" },
  { path: "/faq", component: Faq, title: "FAQ's" },
  { path: "/about-us", component: cmsPage, title: "About Us" },
  { path: "/collaboration", component: cmsPage, title: "Collaboration" },
  { path: "/sugarmd-app", component: cmsPage, title: "SugarMD App" },
  { path: "/data-syncing", component: cmsPage, title: "Data Syncing" },
  { path: "/privacy-policy", component: cmsPage, title: "Privacy Policy" },
  { path: "/terms-of-sale", component: cmsPage, title: "Terms of Sale" },
  { path: "/terms-of-use", component: cmsPage, title: "Terms of Use" },
  { path: "/contact-us", component: ContactUs, title: "Contact Us" },
  { path: "/404", component: NotFoundPage, title: "404" },
];

export const WithOutRoutesPage = [
  { path: "/googleLogin/:id", component: googleLogin, title: "googleLogin" },
];

export const RoutesAuth = [
  { path: "/login", component: LoginPage, title: "Login" },
  { path: "/register", component: RegisterPage, title: "Sign Up" },
  {
    path: "/forgot-password",
    component: ForgotPasswordPage,
    title: "Forgot Password",
  },
  {
    path: "/reset-password/:token",
    component: ResetPasswordPage,
    title: "Reset Password",
  },
  {
    path: "/reset-password",
    component: ResetPasswordPage,
    title: "Reset Password",
  },
];
