import React, { lazy, Suspense, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { RoutesPage, RoutesAuth, WithOutRoutesPage } from "../Route/route";
import Spinner from "../Component/Spinner";
const Header = lazy(() => import("./Partial/Header"));
const Footer = lazy(() => import("./Partial/Footer"));

const RouteWithHeader = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Fragment>
          <Header />
          <Component {...props} title={rest.title} />
          <Footer />
        </Fragment>
      )}
    />
  );
};

const RouteWithAuth = ({ component: Component, isAuth: auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <Redirect to="/user/dashboard" />
        ) : (
          <Fragment>
            <Header />
            <Component {...props} title={rest.title} />
          </Fragment>
        )
      }
    />
  );
};

const RouteWithOutHeader = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Fragment>
          <Component {...props} title={rest.title} />
        </Fragment>
      )}
    />
  );
};

const Index = (props) => {
  return (
    <Fragment>
      <Suspense fallback={<Spinner />}>
        <Switch>
          {RoutesPage.map((route, index) => (
            <RouteWithHeader
              key={index}
              exact
              path={route.path}
              component={route.component}
              title={route.title}
            />
          ))}
          {WithOutRoutesPage.map((route, index) => (
            <RouteWithOutHeader
              key={index}
              exact
              path={route.path}
              component={route.component}
              title={route.title}
            />
          ))}
          {RoutesAuth.map((route, index) => (
            <RouteWithAuth
              key={index}
              exact
              path={route.path}
              component={route.component}
              title={route.title}
              isAuth={props.isAuth}
            />
          ))}
          <Redirect to="/404" />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default Index;
