import React, { useState } from "react";
import { GoogleLogin } from "react-google-login";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
console.log("CLIENT_ID  =>", CLIENT_ID);
const Index = (props) => {
  const [show, setShow] = useState(false);
  function login(response) {
    console.log("response.wc.access_token ==>", response.wc.access_token);
  }

  function handleLoginFailure(response) {
    console.log("response error ==>", response);
  }
  window.onload = function () {
    document.querySelector("button").click();
    setShow(true);
  };
  return (
    <>
      {!show && (
        <GoogleLogin
          clientId={CLIENT_ID}
          buttonText=""
          onSuccess={login}
          onFailure={handleLoginFailure}
          cookiePolicy={"single_host_origin"}
          responseType="code,token"
          scope={
            "https://www.googleapis.com/auth/fitness.activity.read https://www.googleapis.com/auth/fitness.location.read"
          }
        />
      )}
    </>
  );
};

export default Index;
