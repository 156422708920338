import React, { Fragment } from "react";
import {
  appStore,
  googlePlay,
  mobile,
  partner,
  care,
  recoarding,
  Graph,
  question,
  question2,
  profile,
} from "../../assets/images";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var TestimonialsSlider = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    { breakpoint: 1439, settings: { slidesToShow: 2 } },
    { breakpoint: 1365, settings: { slidesToShow: 2 } },
    { breakpoint: 1199, settings: { slidesToShow: 2 } },
    { breakpoint: 991, settings: { slidesToShow: 1 } },
    { breakpoint: 767, settings: { slidesToShow: 1 } },
    { breakpoint: 575, settings: { slidesToShow: 1 } },
    { breakpoint: 479, settings: { slidesToShow: 1 } },
  ],
};

const Home = () => {
  const data = [1, 2, 3, 4];
  return (
    <Fragment>
      <div className="banner_sec banner_bg1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="bannertext">
                <h1>
                  Make Tracking <span>Blood Glucose More Meaningful</span>
                </h1>
                <p>
                  Turn data into informative analyses and learn how to manage
                  diabetes
                </p>
                <div className="btns">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.sugarmd"
                    target="_blank"
                  >
                    <img src={googlePlay} alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/in/app/sugarmd/id1591017644"
                    target="_blank"
                  >
                    <img src={appStore} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="mobile">
                <img src={mobile} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="diabetescare boxbg">
        <div className="container">
          <div className="titleinner">
            <h2>
              Around-the-clock <span>Diabetes Care</span>
            </h2>
            <p>
              SugarMD builds a personalized care service based on the blood
              glucose values and daily activities you have recorded
            </p>
          </div>

          <div className="row">
            <div className="col-md-4 col-lg-4">
              <div className="carebox">
                <div className="iconbox">
                  <img src={recoarding} alt="recoarding" />
                </div>
                <h3>Simple Recording</h3>
                <p>
                  Use SugarMD Mobile App to keep a full record of your blood
                  glucose data and daily activities, taking full control of your
                  health status.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="carebox">
                <div className="iconbox">
                  <img src={partner} alt="partner" />
                </div>

                <h3>Partner Care</h3>
                <p>
                  Use SugarMD Mobile App to keep a full record of your blood
                  glucose data and daily activities, taking full control of your
                  health status.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="carebox">
                <div className="iconbox">
                  <img src={care} alt="" />
                </div>
                <h3>Personalized Care</h3>
                <p>
                  Use SugarMD Mobile App to keep a full record of your blood
                  glucose data and daily activities, taking full control of your
                  health status.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="morebtns">
            <a
              href="true"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Learn more
            </a>
          </div> */}
        </div>
      </section>
      <section className="diabetescare ">
        <div className="container">
          <div className="titleinner">
            <h2>
              Patient <span>Outcome</span>
            </h2>
            <p>
              On average, users with an initial HbA1c > 7 experience a
              <span>2.0 drop</span> in HbA1c after 3 months of using SugarMD.
            </p>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6  col-lg-6">
              <div className="outcometitle">
                <div>
                  Average HbA1c
                  <span>Change in the first 6 months of use</span>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6  col-lg-6">
              <div className="graph">
                <img src={Graph} alt="Graph" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="diabetescare">
        <div className="container">
          {/* <div className="titleinner">
            <h2>
              Client <span>Speaks</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
          </div> */}
        </div>
        {/* <Slider {...TestimonialsSlider}>
          {data.map((item, index) => (
            <div className="item p-3" key={index}>
              <div className="carebox">
                <h3>Simple Recording</h3>
                <p>
                  <img src={question} className="topleft" alt="question" />
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus. Donec quam felis, ultricies nec, pellentesque
                  eu, pretium quis, sem. Nulla consequat massa quis enim. Donec
                  pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
                  In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
                  justo.
                  <img
                    src={question2}
                    className="bottomright"
                    alt="question2"
                  />
                </p>
                <div className="clientname">
                  <div className="profilepic">
                    <img src={profile} alt="profile" />
                  </div>
                  <span>Beverly Locke</span>
                </div>
              </div>
            </div>
          ))}
        </Slider> */}
      </section>
    </Fragment>
  );
};

export default Home;
